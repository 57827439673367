import { getImage, GetStartedLink, ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { metadata } from '../../../data/metadata/images';
import { CallToActionButton } from '../../button';
import { SectionCount } from '../../count';
import { ResponsivePrimaryText, ResponsiveSectionHeader } from '../../typography';

const SectionStyle = createOwnUpStyle({
  marginLeft: 30,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    mediumAndDown: {
      display: 'block',
      paddingBottom: 30,
      margin: '0 30px'
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

const FirstStyle = createOwnUpStyle({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 30px',
  variants: {
    large: {
      marginLeft: 64
    },
    mediumAndDown: {
      display: 'block'
    }
  }
});

const First = createOwnUpComponent('div', FirstStyle);

const ContentWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingBottom: 30,
  variants: {
    large: {
      width: 'calc(50% - 100px)',
      marginRight: 100
    },
    mediumAndDown: {
      marginRight: 0,
      marginLeft: 0
    }
  }
});

const ContentWrapper = createOwnUpComponent(OwnUpBox, ContentWrapperStyle);

const HeaderStyle = createOwnUpStyle({
  width: '85%',
  marginLeft: 0,
  variants: {
    mediumAndDown: {
      textAlign: 'center'
    },
    medium: {
      fontSize: 40,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 450
    },
    small: {
      fontSize: 40,
      width: '100%'
    },
    xsmall: {
      fontSize: 36,
      width: '100%'
    }
  }
});

const Header = createOwnUpComponent(ResponsiveSectionHeader, HeaderStyle);

const SecondStyle = createOwnUpStyle({
  marginTop: 20,
  display: 'flex',
  justifyContent: 'center'
});

const Second = createOwnUpComponent(OwnUpBox, SecondStyle);

const ImageWrapperStyle = createOwnUpStyle({
  width: '50%',
  variants: {
    mediumAndDown: {
      paddingBottom: 30,
      width: '100%'
    }
  }
});

const ImageWrapper = createOwnUpComponent(OwnUpBox, ImageWrapperStyle);

export const imgStyle = {
  margin: 'auto',
  position: 'relative' as const,
  width: '100%'
};

const GetStartedButton = ({ callToAction }: { callToAction: string }) => (
  <GetStartedLink discover={`${process.env.GATSBY_DISCOVER}/get-started`}>
    <CallToActionButton>{callToAction}</CallToActionButton>
  </GetStartedLink>
);

export const SmilingHomeownersImage = ({ imageData }: { imageData: ImageQuery }) =>
  getImage(imageData, 'smiling-new-homeowners.jpg', metadata, imgStyle);

export const Section3 = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Section aria-label="section 3">
      <First>
        <ContentWrapper>
          <SectionCount>{3}</SectionCount>
          <Header>We built a better way to finance a home.</Header>
          <ResponsivePrimaryText>
            We use technology to simplify the home financing process. Our easy-to-use online tools
            help you evaluate lenders, understand how much home you can afford, compare loan offers,
            and even update pre-approval letters on-demand &mdash; a mortgage industry first.
          </ResponsivePrimaryText>
        </ContentWrapper>
        <ImageWrapper>{children}</ImageWrapper>
      </First>
      <Second>
        <GetStartedButton callToAction="SEE FOR YOURSELF"></GetStartedButton>
      </Second>
    </Section>
  );
};
